// TODO:[migration] remove useless breakpoints
export const breakpoints = {
  mobileXS: 0,
  mobileSM: 320,
  mobileMD: 480,
  mobileLG: 640,
  tabletXS: 768,
  tabletSM: 1024,
  tabletMD: 1100,
  tabletLG: 1200,
  desktopXS: 1280,
  desktopSM: 1360,
  desktopMD: 1440,
  desktopLG: 1920,
  desktopXL: 2560,
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobileXS: true;
    mobileSM: true;
    mobileMD: true;
    mobileLG: true;
    tabletXS: true;
    tabletSM: true;
    tabletMD: true;
    tabletLG: true;
    desktopXS: true;
    desktopSM: true;
    desktopMD: true;
    desktopLG: true;
    desktopXL: true;
  }
}
