'use client';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import { createTheme, alpha } from '@mui/material/styles';
import localFont from 'next/font/local';

import { IBMPlexMono } from 'assets/fonts/IBMPlexMono';

import { colors } from './colors';
import { breakpoints } from './breakpoints';

const ptMono = localFont({
  src: [
    {
      path: '../../assets/fonts/PTMonoRegular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/PTMonoRegular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/PTMonoBold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/PTMonoBold.woff',
      weight: '700',
      style: 'normal',
    },
  ],
  fallback: ['Mono', 'monospace'],
});

const defaultTheme = createTheme();

export const zIndex = {
  ...defaultTheme.zIndex,
  pageHeader: defaultTheme.zIndex.modal - 20,
};

export const theme = createTheme({
  breakpoints: { values: breakpoints },
  typography: {
    fontFamily: ptMono.style.fontFamily,
    secondaryFontFamily: IBMPlexMono.style.fontFamily,
    body2: {
      fontSize: 12,

      '@media (min-width: 2560px)': {
        fontSize: 15,
      },
    },
  },
  spacing: 5,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: ptMono.style.fontFamily,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
          textTransform: 'none',
          minWidth: 0,

          '&:hover, &:active': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: '8px 10px 7px',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '13px 20px 12px',
            fontSize: 13,
            lineHeight: 1.2,
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '10px 18px',
            fontSize: 15,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: colors.black,

            '&:hover, &:active': {
              color: colors.black,
              backgroundColor: colors.white,
            },

            '&.Mui-disabled': {
              color: colors.black,
              backgroundColor: colors.white,
              opacity: 0.5,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.white,
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.white,

            '&:hover, &:active': {
              backgroundColor: colors.white,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: colors.white,
            border: 'none',

            '&:hover, &:active': {
              border: 'none',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            boxShadow: `0 0 0 1px ${colors.charade},
              0 0 0 1px ${colors.charade} inset`,

            '&:hover, &:active': {
              background: colors.charade,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            boxShadow: `0 0 0 1px ${colors.brightGray},
            0 0 0 1px ${colors.brightGray} inset`,

            '&:hover, &:active': {
              background: colors.brightGray,
            },
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            padding: '2px 8px',
            borderRadius: 15,
            color: colors.white,
            backgroundColor: colors.ebonyClay,
            fontSize: 12,
            lineHeight: 1,

            '&:hover, &:active': {
              color: colors.black,
              backgroundColor: colors.white,
            },

            '&.Mui-disabled': {
              color: colors.black,
              backgroundColor: colors.white,
              opacity: 0.5,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: '2px 8px',
            borderRadius: 15,
            color: colors.white,
            backgroundColor: 'transparent',
            fontSize: 12,
            lineHeight: 1,

            '&:hover, &:active': {
              backgroundColor: colors.ebonyClay,
            },

            '&.Mui-disabled': {
              color: 'rgba(255,255,255,0.5)',
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 0,

            '& .MuiOutlinedInput-root': {
              paddingLeft: 10,
              border: '2px solid #16181e',
              borderRadius: 6,
              backgroundColor: colors.charade,
            },

            '& .MuiOutlinedInput-input': {
              height: 'auto',
              padding: '10px 10px',
              borderRadius: 6,
              fontSize: 12,
              lineHeight: 1,
              color: colors.white,

              '&::placeholder': {
                color: '#fff',
                opacity: 1,
              },
            },

            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: ({ ownerState: { underline } }) => ({
          position: 'relative',
          display: 'inline-block',
          textDecoration: 'none',
          color: colors.white,
          transition: defaultTheme.transitions.create('all'),

          '&:hover, &:active': {
            color: colors.white,
          },

          ...(underline === 'always'
            ? {
                textDecoration: 'underline rgba(255, 255, 255, 0.2)',
                textDecorationThickness: '1px',
                textUnderlineOffset: '0.55em',

                '&:hover, &:active': {
                  textDecorationColor: colors.white,
                },
              }
            : {}),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '6px 10px',
          border: 'solid 2px #000',
          borderRadius: 0,
          background: colors.fiord,
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          backgroundColor: colors.woodsmoke,
          color: 'rgba(255,255,255,0.6)',
        },
        contentWrapper: {
          '& .MuiTypography-root': {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: colors.woodsmoke,
          color: 'rgba(255,255,255,0.6)',

          '&:hover': {
            backgroundColor: `rgba(255,255,255,0.1) !important`,
          },

          '&.Mui-selected': {
            backgroundColor: `${colors.electricViolet} !important`,
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: `${colors.electricViolet} !important`,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: alpha(colors.woodsmoke, 0.9),
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: { elevation: 0, variant: 'outlined', square: true },
      },
      styleOverrides: {
        root: {
          '& .MuiPaper-outlined': {
            border: `2px solid ${colors.black}`,
          },

          '& .MuiPaper-outlined .MuiDialogTitle-root': {
            padding: 20,
            fontSize: 15,
            lineHeight: 1.3,
            color: colors.white,
            borderBottom: `2px solid ${colors.black}`,

            [defaultTheme.breakpoints.up(breakpoints.tabletXS)]: {
              padding: '30px 30px 20px',
            },
          },

          '& .MuiPaper-outlined .MuiDialogContent-root': {
            padding: '20px !important',

            [defaultTheme.breakpoints.up(breakpoints.tabletXS)]: {
              padding: '30px !important',
            },
          },
        },
        paper: {
          background: colors.ebonyClay2,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: {
          color: colors.white,
          padding: 5,
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            fontSize: 20,
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -5,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {},
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '5px 10px 18.5px',
          minWidth: 75,
          color: colors.white,
          fontSize: '1rem',
          textTransform: 'none',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        width: 0,
      },
      styleOverrides: {
        root: {
          fontSize: 20,
          display: 'block',
        },
        fontSizeSmall: {
          fontSize: 16,
        },
        fontSizeLarge: {
          fontSize: 24,
        },
        fontSizeInherit: {
          fontSize: 'inherit',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 40,
          height: 20,
          borderRadius: 10,
          alignItems: 'center',
        },
        switchBase: {
          padding: 0,
          color: colors.riverBed,
          top: 'auto',
          left: 5,
          transition: defaultTheme.transitions.create(['color', 'transform']),

          '.Mui-checked&': {
            color: colors.white,
            transform: 'translateX(15px)',
          },

          '.Mui-checked.Mui-disabled&, .Mui-disabled&': {
            color: 'rgba(255, 255, 255, 0.3)',
          },

          '& .MuiTouchRipple-root': {
            top: -5,
            right: -5,
            bottom: -5,
            left: -5,
            color: colors.white,
          },
        },
        thumb: {
          width: 14,
          height: 10,
          borderRadius: 5,
          boxShadow: 'none',
        },
        track: {
          color: colors.riverBed,
          border: '2px solid currentColor',
          opacity: 1,
          backgroundColor: 'transparent',
          borderRadius: 'inherit',
          transition: defaultTheme.transitions.create('color'),

          '.MuiSwitch-switchBase.Mui-checked + &&': {
            opacity: 1,
            color: colors.white,
            backgroundColor: 'transparent',
          },

          '.MuiSwitch-switchBase.Mui-disabled + &&': {
            color: 'rgba(255, 255, 255, 0.3)',
            opacity: 1,
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.white,
    },
    background: {
      paper: colors.charade,
      default: colors.woodsmoke,
    },
    action: {
      disabled: colors.whiteOpacity[24],
      active: colors.whiteOpacity[48],
    },
    warning: {
      main: colors.brightOrange,
    },
    success: {
      main: colors.white,
    },
    error: {
      main: colors.bittersweet,
    },
    text: {
      primary: colors.white,
      secondary: colors.white,
    },
    amount: {
      positive: colors.springGreen[100],
      negative: colors.scarlet[100],
    },
    progress: {
      default: colors.electricViolet,
      positive: colors.springGreen[100],
      negative: colors.scarlet[100],
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedShadow: true;
    containedSecondary: true;
    rounded: true;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    secondaryFontFamily: React.CSSProperties['fontFamily'];
  }

  interface TypographyOptions {
    secondaryFontFamily: React.CSSProperties['fontFamily'];
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    amount: {
      positive: string;
      negative: string;
    };
    progress: {
      default: string;
      positive: string;
      negative: string;
    };
  }

  interface PaletteOptions {
    amount: {
      positive: string;
      negative: string;
    };
    progress: {
      default: string;
      positive: string;
      negative: string;
    };
  }
}
