// Find color name http://chir.ag/projects/name-that-color
// https://github.com/insomnious0x01/ntc-js

export const colors = {
  black: '#000',
  blackOpacity: {
    4: 'rgba(0, 0, 0, 0.04)',
    5: 'rgba(0, 0, 0, 0.05)',
    6: 'rgba(0, 0, 0, 0.06)',
    8: 'rgba(0, 0, 0, 0.08)',
    12: 'rgba(0, 0, 0, 0.12)',
    16: 'rgba(0, 0, 0, 0.16)',
    24: 'rgba(0, 0, 0, 0.24)',
    26: 'rgba(0, 0, 0, 0.26)',
    32: 'rgba(0, 0, 0, 0.32)',
    40: 'rgba(0, 0, 0, 0.4)',
    48: 'rgba(0, 0, 0, 0.48)',
    50: 'rgba(0, 0, 0, 0.50)',
    54: 'rgba(0, 0, 0, 0.54)',
    64: 'rgba(0, 0, 0, 0.64)',
    68: 'rgba(0, 0, 0, 0.68)',
    80: 'rgba(0, 0, 0, 0.80)',
  },
  white: '#fff',
  whiteOpacity: {
    4: 'rgba(255, 255, 255, 0.04)',
    5: 'rgba(255, 255, 255, 0.05)',
    8: 'rgba(255, 255, 255, 0.08)',
    12: 'rgba(255, 255, 255, 0.12)',
    16: 'rgba(255, 255, 255, 0.16)',
    24: 'rgba(255, 255, 255, 0.24)',
    32: 'rgba(255, 255, 255, 0.32)',
    40: 'rgba(255, 255, 255, 0.4)',
    48: 'rgba(255, 255, 255, 0.48)',
    64: 'rgba(255, 255, 255, 0.64)',
    68: 'rgba(255, 255, 255, 0.68)',
    80: 'rgba(255, 255, 255, 0.80)',
  },
  smokyWhite: '#EEEEEE',
  pearlDarkGray: '#848484',
  silver: '#C4C4C4',
  grayOpacity: {
    16: 'rgba(112, 112, 112, 0.16)',
    24: 'rgba(112, 112, 112, 0.24)',
    32: 'rgba(112, 112, 112, 0.32)',
    48: 'rgba(112, 112, 112, 0.48)',
    100: 'rgba(112, 112, 112, 1)',
  },
  middleGray: '#d9d9d9',
  swansDown: '#d4ede4',
  edgewater: '#c5dfd6',
  torchRed: '#FF141C',
  woodsmoke: '#16181e',
  ebonyClay: '#2e3443',
  charade: '#232731',
  chambray: '#31478c',
  ebonyClay2: '#282d39',
  bittersweet: '#ff696e',
  electricViolet: '#7325ff',
  fiord: '#434c63',
  brightGray: '#343A49',
  blushPink: '#ff86ee',
  melrose: '#cdb2ff',
  midGray: '#646466',
  riverBed: '#4d566a',
  mercury: '#e7e7e7',
  congoBrown: '#673D3F',
  oxfordBlue: '#374159',
  dolly: '#c8b60d',
  trout: '#4D5467',
  veryDark: '#242730',
  green: '#ADFF86',
  yellow: '#FFF386',
  orange: '#FFC086',
  red: '#FF8686',
  red2: '#FF0F00',
  brightRedOrange: {
    100: '#EF1B1B',
    16: 'rgba(239, 27, 27, 0.16)',
  },
  transportBlack: '#1F1F1F',
  transportBlack2: '#2F2F2F',
  signalBlack: '#2C2C2C',
  signalBlack2: '#323232',
  signalBlack3: '#292929',
  springGreen: {
    100: '#00E291',
    36: 'rgba(0, 226, 145, 0.36)',
    12: 'rgba(0, 226, 145, 0.12)',
  },
  scarlet: {
    100: '#FF1F00',
    16: 'rgba(255, 31, 0, 0.16)',
  },
  scarlet2: '#FF2E00',
  brightOrange: '#FF6B00',
  greenShamrock: '#0B9C68',
  richYellow: '#E29726',
  lightTitian: '#E24826',
  blueGradusa: '#0167FF',
  mildSlateBlue: '#885FFF',
  persianBlue: '#3401FF',
  frostySky: '#08CDF9',
  lightGray: {
    100: '#BABABA',
    60: 'rgba(186, 186, 186, 0.6)',
    40: 'rgba(186, 186, 186, 0.4)',
  },
  dahliaYellow: '#F99808',
};
